import * as _forEach2 from "for-each";

var _forEach = "default" in _forEach2 ? _forEach2.default : _forEach2;

import * as _availableTypedArrays2 from "available-typed-arrays";

var _availableTypedArrays = "default" in _availableTypedArrays2 ? _availableTypedArrays2.default : _availableTypedArrays2;

import * as _callBound2 from "call-bind/callBound";

var _callBound = "default" in _callBound2 ? _callBound2.default : _callBound2;

import * as _shams2 from "has-tostringtag/shams";

var _shams = "default" in _shams2 ? _shams2.default : _shams2;

import * as _getOwnPropertyDescriptor2 from "es-abstract/helpers/getOwnPropertyDescriptor";

var _getOwnPropertyDescriptor = "default" in _getOwnPropertyDescriptor2 ? _getOwnPropertyDescriptor2.default : _getOwnPropertyDescriptor2;

import * as _isTypedArray2 from "is-typed-array";

var _isTypedArray = "default" in _isTypedArray2 ? _isTypedArray2.default : _isTypedArray2;

var _global = typeof globalThis !== "undefined" ? globalThis : typeof self !== "undefined" ? self : global;

var exports = {};
var forEach = _forEach;
var availableTypedArrays = _availableTypedArrays;
var callBound = _callBound;
var $toString = callBound("Object.prototype.toString");

var hasToStringTag = _shams();

var g = typeof globalThis === "undefined" ? _global : globalThis;
var typedArrays = availableTypedArrays();
var $slice = callBound("String.prototype.slice");
var toStrTags = {};
var gOPD = _getOwnPropertyDescriptor;
var getPrototypeOf = Object.getPrototypeOf; // require('getprototypeof');

if (hasToStringTag && gOPD && getPrototypeOf) {
  forEach(typedArrays, function (typedArray) {
    if (typeof g[typedArray] === "function") {
      var arr = new g[typedArray]();

      if (Symbol.toStringTag in arr) {
        var proto = getPrototypeOf(arr);
        var descriptor = gOPD(proto, Symbol.toStringTag);

        if (!descriptor) {
          var superProto = getPrototypeOf(proto);
          descriptor = gOPD(superProto, Symbol.toStringTag);
        }

        toStrTags[typedArray] = descriptor.get;
      }
    }
  });
}

var tryTypedArrays = function tryAllTypedArrays(value) {
  var foundName = false;
  forEach(toStrTags, function (getter, typedArray) {
    if (!foundName) {
      try {
        var name = getter.call(value);

        if (name === typedArray) {
          foundName = name;
        }
      } catch (e) {}
    }
  });
  return foundName;
};

var isTypedArray = _isTypedArray;

exports = function whichTypedArray(value) {
  if (!isTypedArray(value)) {
    return false;
  }

  if (!hasToStringTag || !(Symbol.toStringTag in value)) {
    return $slice($toString(value), 8, -1);
  }

  return tryTypedArrays(value);
};

export default exports;